/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Noto+Sans+TC:wght@300;400;500&family=Parisienne&family=Patrick+Hand&family=Roboto:wght@300;400;500&display=swap');

body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
html {
    font-size: 14px;
}
p {
    font-size: 14px;
}
span {
    font-size: 14px;
}
* {
    font-family: Roboto, PingFang TC, Noto Sans TC;
    box-sizing: border-box;
}
p + p {
    margin-top: 0 !important;
}
a, a:hover, a:focus{
    text-decoration: none!important;
}
@media (min-width: 1600px) {
    html {
        font-size: 15px;
    }
    p {
        font-size: 15px;
    }
    span {
        font-size: 15px;
    }
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: #5295FF !important;
}

.MuiTab-textColorPrimary.Mui-selected {
    background-color: #fff;
}

video::-webkit-media-controls-fullscreen-button {
    display: none;
}
video::-webkit-media-controls {
    display: none !important;
}
video::-webkit-media-controls-enclosure {
    display: none !important;
}